import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "../Pages/Landing.css";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <button
      className="bg-[#FC5A7B] text-lg text-white font-semibold py-2 px-8 hover:-translate-y-1 duration-200 ease-in-out"
      onClick={() => loginWithRedirect()}
    >
      Get started
    </button>
  );
};

export default LoginButton;
