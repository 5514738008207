import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Landing from "./Pages/Landing";
import Dashboard from "./Pages/Dashboard";
import Onboarding from "./Pages/Onboarding";
import posthog from "posthog-js";

function App() {
  posthog.init("phc_v9p8cBsLqKJ0hAIZKVCB6BjIi1hzZFSSK84zU02LcVH", {
    api_host: "https://app.posthog.com",
  });

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="*" element={<Landing />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="onboarding" element={<Onboarding />} />
      </Routes>
    </Router>
  );
}

export default App;
