import React from "react";
import { XCircle } from "lucide-react";
import { useState, useEffect, useRef } from "react";
import stocksData from "./stocks.json";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import * as CryptoJS from "crypto-js";
import { getUserData, updateUserData } from "../Pages/firebase";
import "./Landing.css";

function Dashboard({}) {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [loadingPage, setLoadingPage] = useState(true);
  const [uid, setUid] = useState("");

  const navigate = useNavigate("");
  const authCheckedRef = useRef(false);

  const [stockList, setStockList] = useState([]);
  const [isEnabled, setIsEnabled] = useState(false);
  const [newChanges, setNewChanges] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [userStocks, setUserStocks] = useState([]);
  const [updateIsOn, setUpdateIsOn] = useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState("");
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState("");
  const [selectedFrequency, setSelectedFrequency] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const hash = CryptoJS.SHA256(user.email).toString();
      setUid(hash);
      console.log(hash);
      const userData = await getUserData(hash);

      if (userData) {
        console.log(userData);
        setSelectedTimezone(userData.timezone);
        setUserStocks(userData.stocks);
        setUpdateIsOn(userData.toSend);
        setSelectedPhoneNumber(userData.phone);
        setSelectedFrequency(userData.frequency);
        console.log(userData.frequency);

        // existing
        setIsEnabled(userData.toSend);
        setSelectedStocks(userData.stocks);
      } else {
        console.log("ERROR");
      }
    };

    const checkAuthentication = () => {
      if (isAuthenticated) {
        console.log("User is authenticated");
        authCheckedRef.current = true; // Update ref instead of state
        fetchData();
        setLoadingPage(false);
      }
    };

    const intervalId = setInterval(checkAuthentication, 200);

    const timeoutId = setTimeout(() => {
      clearInterval(intervalId); // Stop the interval

      // Use the ref to check if the authentication status has been confirmed
      if (!isAuthenticated && !authCheckedRef.current) {
        console.log("User is not authenticated after 2 seconds");
        navigate("/"); // Navigate if not authenticated
      }
    }, 5000);

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId); // Also clear the timeout when cleaning up
    };
  }, [isAuthenticated]);

  const [savingChanges, setSavingChanges] = useState(false);
  async function saveNewChanges() {
    setSavingChanges(true);
    let response = await updateUserData(
      updateIsOn,
      selectedTimezone,
      selectedStocks,
      selectedPhoneNumber,
      selectedFrequency,
      uid
    );

    setSavingChanges(false);
    setNewChanges(false);
    setSuccessMessage("Success!");
  }

  const toggleState = () => {
    setIsEnabled(!isEnabled);
    setNewChanges(true);
    setSuccessMessage("");
    setUpdateIsOn(!isEnabled);
  };

  function setPhoneNumberValue(event) {
    setSelectedPhoneNumber(event.target.value);
    setNewChanges(true);
  }

  const handleTimezoneChange = (event) => {
    setSelectedTimezone(event.target.value);
    setNewChanges(true);
    setSuccessMessage("");
  };

  function handleSelectedFrequency(event) {
    setSelectedFrequency(event.target.value);
    setNewChanges(true);
    setSuccessMessage("");
  }

  useEffect(() => {
    // Render if call on/off
    // Render stocks for account

    if (stocksData.length > 0) {
      setStockList(stocksData);
    }
  }, []);

  const [selectedStocks, setSelectedStocks] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const handleInput = (e) => {
    setInputValue(e.target.value);
    const matchingStocks = stockList.filter((stock) =>
      stock.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setSuggestions(matchingStocks);
  };
  const addStock = (stock) => {
    if (selectedStocks.length < 3 && !selectedStocks.includes(stock)) {
      setSelectedStocks([...selectedStocks, stock]);
      console.log(selectedStocks);
      setInputValue("");
      setSuggestions([]);
      setNewChanges(true);
      setSuccessMessage("");
    }
  };
  const removeStock = (name) => {
    setSelectedStocks(selectedStocks.filter((stock) => stock.name !== name));
    setNewChanges(true);
    setSuccessMessage("");
  };
  return (
    <div className="mx-auto max-w-lg px-10">
      {loadingPage ? (
        <div className="mt-60 flex flex-row justify-center items-center">
          <div
            role="status"
            class="max-w-md p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700"
          >
            <div class="flex items-center justify-between">
              <div>
                <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              </div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
            </div>
            <div class="flex items-center justify-between pt-4">
              <div>
                <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              </div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
            </div>
            <div class="flex items-center justify-between pt-4">
              <div>
                <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              </div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
            </div>
            <div class="flex items-center justify-between pt-4">
              <div>
                <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              </div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
            </div>
            <div class="flex items-center justify-between pt-4">
              <div>
                <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              </div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
            </div>
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div>
          <div className="mt-20">
            <h1 className="text-center font-sans text-4xl font-semibold text-[#C2EABD]">
              Your update
            </h1>
            <p className="mt-2 text-center text-white text-sm">
              Save Deborah's number to receive your update!
            </p>
            <p className="mt-1 text-center text-white text-sm">+14153607108</p>
          </div>
          <div className="mt-6 relative border border-white border-solid p-4 flex flex-col">
            <div>
              <h1 className="text-[#F6899F] font-semibold text-lg">Timezone</h1>
              <div className="">
                <select
                  id="timezone-select"
                  value={selectedTimezone}
                  onChange={handleTimezoneChange}
                  className="bg-[#011936] -ml-0.5 text-white block w-full text-base focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm rounded-md"
                >
                  <option value="">Please choose...</option>
                  <option value="EST">Eastern Standard (UTC-5)</option>
                  <option value="CST">Central Standard (UTC-6)</option>
                  <option value="MST">Mountain Standard (UTC-7)</option>
                  <option value="PST">Pacific Standard (UTC-8)</option>
                </select>
              </div>
            </div>
            <div>
              <h1 className="mt-6 text-[#F6899F] font-semibold text-lg">
                Phone number
              </h1>
              <input
                value={selectedPhoneNumber}
                onChange={setPhoneNumberValue}
                className="text-sm mt-1 border bg-[#011936] -ml-0.5 text-white border border-gray-300 p-2 w-full"
                placeholder="800123456"
              ></input>
            </div>
            <div>
              <h1 className="mt-6 text-[#F6899F] font-semibold text-lg">
                Your portfolio
              </h1>
              <h1 className="text-white font-normal text-xs">Choose up to 3</h1>
              <input
                type="text"
                value={inputValue}
                onChange={handleInput}
                className="border border-gray-300 p-2 w-full mt-2 text-sm text-white bg-[#011936] -ml-0.5"
                placeholder="Type a stock name..."
                aria-label="Stock input"
              />
              {suggestions.length > 0 && (
                <ul className="text-white bg-[#011936] absolute z-10 w-full border border-gray-300 mt-0.5 max-h-60 overflow-auto">
                  {suggestions.map((stock) => (
                    <li
                      key={stock.id}
                      className="p-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => addStock(stock)}
                    >
                      {stock.name}
                    </li>
                  ))}
                </ul>
              )}
              <div className="mt-3">
                {selectedStocks.map((stock) => (
                  <div
                    key={stock.id}
                    className="flex items-center justify-between py-1 text-white bg-[#011936] font-light text-sm"
                  >
                    <span>{stock.name}</span>
                    <button
                      className="text-gray-100 hover:text-gray-300"
                      onClick={() => removeStock(stock.name)}
                      aria-label={`Remove ${stock.name}`}
                    >
                      <XCircle />
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <h1 className="text-[#F6899F] font-semibold mt-4 text-lg">
                Frequency
              </h1>

              <div className="">
                <select
                  id="frequency-select"
                  value={selectedFrequency}
                  onChange={handleSelectedFrequency}
                  className="bg-[#011936] -ml-0.5 text-white block w-full text-base focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm rounded-md"
                >
                  <option value="">Please choose...</option>
                  <option value="Daily">Daily</option>
                  <option value="Weekdays (M-F)">Weekdays (M-F)</option>
                  <option value="Never">Never</option>
                </select>
              </div>

              {/* oLD SHIT */}
            </div>
          </div>

          <div className="mt-6 mb-10">
            {newChanges ? (
              <div className="mt-6 flex flex-row justify-center items-center">
                <button
                  onClick={saveNewChanges}
                  className="bg-[#C2EABD] text-black text-sm font-medium px-4 py-2 hover:-translate-y-1 duration-200 ease-in-out"
                >
                  {savingChanges ? "Loading..." : "Save Changes"}
                </button>
              </div>
            ) : (
              <div></div>
            )}
            <p className="text-center font-medium text-[#C2EABD] mt-2">
              {successMessage}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
export default Dashboard;
