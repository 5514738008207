import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoginButton from '../Components/LoginButton';
import './Landing.css';

function Landing({}){

    const navigate = useNavigate("");

    useEffect(() => {
    }, [])

    return(
        <div className="m-0 p-0 bg-[#011936] max-w-6xl my-30 lg:max-w-4xl">
            <h1 className="mt-32 mx-16 text-5xl font-semibold text-left text-[#C2EABD]">Receive free, <span className="text-[#F6899F] italic">over-the-phone</span> portfolio updates every day</h1>
            <h1 className="mt-6 mx-16 text-xl leading-8 text-white font-normal">Start your day with a ~3 minute portfolio digest from our AI financial analyst <span className="font-medium text-[#F6899F]">Deborah</span>.</h1>
            {/* <div className="flex flex-row justify-center items-center">
                <img src='https://i.imgur.com/RdBrtB4.png' className="w-48 h-48 mt-4" />
            </div> */}
            {/* <p className="font-sans text-lg text-center mt-2 px-20">receive a free daily phone call digest for your stock portfolio </p> */}
            <div className="mx-16 mt-6">
                <LoginButton />
                {/* <input className="border border-solid border-black p-2" placeholder="123 456 789"></input>
                <button className="ml-2 p-2 bg-black text-white text-center">Get started</button> */}
            </div>
            <div className="mt-16 mx-16 flex flex-col justify-start items-start mb-10">
            </div>
        </div>
    )
} export default Landing;