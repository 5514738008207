import "./Landing.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PreferenceBox from "./PreferenceBox";
import { checkIfOnboarded } from "./firebase";
import { useAuth0 } from "@auth0/auth0-react";
import * as CryptoJS from "crypto-js";

function Onboarding({}) {
  const navigate = useNavigate("");

  const [checkingIfOnboarded, setCheckingIfOnboarded] = useState(true);
  const { user, isAuthenticated, isLoading } = useAuth0();

  async function checkForOnboard(uid) {
    let isOnboarded = await checkIfOnboarded(uid);
    if (isOnboarded) {
      navigate("/dashboard");
    }
    setCheckingIfOnboarded(false);
  }

  useEffect(() => {
    if (user && typeof user.email !== "undefined") {
      const hash = CryptoJS.SHA256(user.email).toString();
      checkForOnboard(hash);
    }
  }, [user]);

  return (
    <div className="mx-auto max-w-lg px-10">
      {checkingIfOnboarded ? (
        <div></div>
      ) : (
        <div>
          <PreferenceBox />
        </div>
      )}
    </div>
  );
}

export default Onboarding;
