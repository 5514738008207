// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { doc, setDoc, getDoc, getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBz6UVFy2fMANgULgysu8AutebmyLX5QcI",
  authDomain: "financial-ai-e206e.firebaseapp.com",
  projectId: "financial-ai-e206e",
  storageBucket: "financial-ai-e206e.appspot.com",
  messagingSenderId: "382843799462",
  appId: "1:382843799462:web:84a4288f61178b47c9b167",
  measurementId: "G-GMNTF3P6P6",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export async function updateUserData(
  toSend,
  timezone,
  stocks,
  phone,
  frequency,
  uid,
  onboarded = true
) {
  try {
    const userDocRef = doc(db, "users", uid); // Create a reference to the user document

    await setDoc(
      userDocRef,
      {
        toSend: toSend,
        timezone: timezone,
        stocks: stocks,
        phone: phone,
        frequency: frequency,
        onboarded: onboarded,
      },
      { merge: true }
    ); // Overwrite data with merge option

    return "success";
  } catch (error) {
    console.error("Error updating user data:", error);
    return "failure";
  }
}

export async function getUserData(uid) {
  console.log(`this is the uid i pass to getuserdata: ${uid}`);
  try {
    const userDocRef = doc(db, "users", uid);
    const docSnapshot = await getDoc(userDocRef);

    if (docSnapshot.exists()) {
      return docSnapshot.data(); // Return the user data
    } else {
      return null; // Or handle the case where the document doesn't exist
    }
  } catch (error) {
    console.error("Error getting user data:", error);
    return null; // Or handle the error appropriately
  }
}

export async function checkIfOnboarded(uid) {
  try {
    const userDocRef = doc(db, "users", uid);
    const docSnapshot = await getDoc(userDocRef);

    if (docSnapshot.exists()) {
      return docSnapshot.data(); // Return the user data
    } else {
      return null; // Or handle the case where the document doesn't exist
    }
  } catch (error) {
    console.error("Error getting user data:", error);
    return null; // Or handle the error appropriately
  }
}
