import React, { useState, useEffect } from "react";
import stocksData from "../Pages/stocks.json";
import { XCircle } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { checkIfOnboarded, updateUserData } from "../Pages/firebase";
import { useAuth0 } from "@auth0/auth0-react";
import * as CryptoJS from "crypto-js";

function PreferenceBox({}) {
  const navigate = useNavigate("");
  const [uid, setUid] = useState("");
  const { user, isAuthenticated, isLoading } = useAuth0();

  const [stockList, setStockList] = useState([]);

  const [timezone, setTimezone] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [stockInputValue, setStockInputValue] = useState("");
  const [stockSuggestions, setStockSuggestions] = useState("");
  const [selectedStocks, setSelectedStocks] = useState([]);
  const [frequency, setFrequency] = useState("");

  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState("");
  const [setupErrorMessage, setSetupErrorMessage] = useState("");
  const [phoneNumberIsValid, setPhoneNumberIsValid] = useState(false);

  const [welcomeCallSuccess, setWelcomeCallSuccess] = useState(true);

  async function makeWelcomeCall(phone) {
    try {
      // Post request to server
      const response = await fetch(
        "https://deborah-daily-portfolio-update-fa25afe4f481.herokuapp.com/intro_call",
        {
          method: "POST", // Specify the request method
          headers: {
            "Content-Type": "application/json", // Specify the content type as JSON
          },
          body: JSON.stringify({ phone_number: phone }), // Convert the JavaScript object to a JSON string
        }
      );

      if (!response.ok) {
        // Handle response errors
        throw new Error("Network response was not ok");
      }

      // Parse JSON response
      const data = await response.json();
      console.log(data.error);
      return data.error;

      // Handle the response data
      console.log("Success:", data);
      // Here, you can set state, trigger alerts, or perform other actions based on the response
    } catch (error) {
      // Handle errors in the request
      console.error("Error:", error);
      return true;
    }
  }

  async function initiateWelcomeCall() {
    if (
      timezone === "" ||
      phoneNumber === "" ||
      selectedStocks === [] ||
      frequency === "" ||
      !phoneNumberIsValid
    ) {
      setSetupErrorMessage("Please fill out all fields to continue.");
      return;
    } else {
      setSlide(2);
      let updateResponse = await updateUserData(
        true,
        timezone,
        selectedStocks,
        phoneNumber,
        frequency,
        uid
      );

      let callResponse = await makeWelcomeCall(phoneNumber);
      if (callResponse) {
        setWelcomeCallSuccess(false);
      } else {
        setWelcomeCallSuccess(true);
      }
    }
  }

  function changeTimezone(event) {
    setTimezone(event.target.value);
  }

  function changePhoneNumber(event) {
    setPhoneNumber(event.target.value);
    validatePhoneNumberInput(event.target.value);
  }

  function changeFrequency(event) {
    setFrequency(event.target.value);
  }

  function changeStockInputValue(event) {
    setStockInputValue(event.target.value);
    const matchingStocks = stockList.filter((stock) =>
      stock.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setStockSuggestions(matchingStocks);
  }

  const addStock = (stock) => {
    if (selectedStocks.length < 3 && !selectedStocks.includes(stock)) {
      setSelectedStocks([...selectedStocks, stock]);
      setStockInputValue("");
      setStockSuggestions([]);
    }
  };
  const removeStock = (name) => {
    setSelectedStocks(selectedStocks.filter((stock) => stock.name !== name));
  };

  function validatePhoneNumberInput(phoneNumber) {
    const regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    let isValid = regex.test(phoneNumber);

    if (!isValid) {
      setPhoneNumberErrorMessage("Please enter a valid phone number.");
      setPhoneNumberIsValid(false);
    } else {
      setPhoneNumberErrorMessage("");
      setPhoneNumberIsValid(true);
    }
  }

  useEffect(() => {
    if (stocksData.length > 0) {
      setStockList(stocksData);
    }

    if (user && typeof user.email !== "undefined") {
      const hash = CryptoJS.SHA256(user.email).toString();
      setUid(hash);
    }
  }, [user]);

  const [slide, setSlide] = useState(1);

  return (
    <div className="mx-auto max-w-3xl">
      {slide === 1 ? (
        <h1 className="mt-20 text-white font-semibold text-center text-3xl">
          To get started, tell us about you.
        </h1>
      ) : slide === 2 ? (
        <h1 className="mt-20 text-white font-semibold text-center text-3xl">
          Nice! You are set up.
        </h1>
      ) : null}
      <div className="mt-6 p-2 flex flex-col">
        {slide === 1 ? (
          <div>
            {" "}
            <div id="timezone">
              <h1 className="text-[#F6899F] font-semibold text-xl">Timezone</h1>
              <div className="">
                <select
                  id="timezone-select"
                  value={timezone}
                  onChange={changeTimezone}
                  className="mt-1 bg-[#011936] -ml-0.5 text-white block w-full text-base focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
                >
                  <option value="">Please choose...</option>
                  <option value="EST">Eastern Standard (UTC-5)</option>
                  <option value="CST">Central Standard (UTC-6)</option>
                  <option value="MST">Mountain Standard (UTC-7)</option>
                  <option value="PST">Pacific Standard (UTC-8)</option>
                </select>
              </div>
            </div>
            <div id="phone-number">
              <h1 className="mt-8 text-[#F6899F] font-semibold text-xl">
                Phone number
              </h1>
              <div className="mt-1 flex flex-row justify-center items-center">
                <p className="text-white mr-3 mt-1 text-base">+1</p>
                <input
                  value={phoneNumber}
                  onChange={changePhoneNumber}
                  className="text-base mt-1 border bg-[#011936] -ml-0.5 text-white border-gray-300 p-2 w-full"
                  placeholder="4156676210"
                ></input>
              </div>
              <p className="text-center text-white text-sm mt-2">
                {phoneNumberErrorMessage}
              </p>
            </div>
            <div id="portfolio">
              <h1 className="mt-8 text-[#F6899F] font-semibold text-xl">
                Portfolio
              </h1>
              <h1 className="text-white font-normal text-sm">Choose up to 3</h1>
              <input
                type="text"
                value={stockInputValue}
                onChange={changeStockInputValue}
                className="border border-gray-300 p-2 w-full mt-3 text-base text-white bg-[#011936] -ml-0.5"
                placeholder="Type a stock name..."
                aria-label="Stock input"
              />
              {stockSuggestions.length > 0 && (
                <ul className="mt-3 text-white bg-[#011936] absolute z-10 border w-auto border-gray-300 max-h-60 overflow-auto">
                  {stockSuggestions.map((stock) => (
                    <li
                      key={stock.id}
                      className="p-2 hover:text-[#F6899F] cursor-pointer text-base"
                      onClick={() => addStock(stock)}
                    >
                      {stock.name}
                    </li>
                  ))}
                </ul>
              )}
              <div className="mt-3">
                {selectedStocks.map((stock) => (
                  <div
                    key={stock.id}
                    className="flex items-center justify-between py-1 text-white bg-[#011936] font-light text-base"
                  >
                    <span>{stock.name}</span>
                    <button
                      className="text-gray-100 hover:text-gray-300"
                      onClick={() => removeStock(stock.name)}
                      aria-label={`Remove ${stock.name}`}
                    >
                      <XCircle />
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <div id="frequency">
              <h1 className="text-[#F6899F] font-semibold mt-8 text-xl">
                Frequency
              </h1>

              <div className="">
                <select
                  id="frequency-select"
                  value={frequency}
                  onChange={changeFrequency}
                  className="mt-1 bg-[#011936] -ml-0.5 text-white block w-full text-base focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
                >
                  <option value="">Please choose...</option>
                  <option value="Daily">Daily</option>
                  <option value="Weekdays (M-F)">Weekdays (M-F)</option>
                </select>
              </div>
            </div>
            <div className="flex flex-row w-full justify-center items-center">
              <button
                className="mt-12 w-full py-2 text-black font-medium hover:-translate-y-1 duration-200 ease-in-out bg-[#C2EABD]"
                onClick={initiateWelcomeCall}
              >
                Continue
              </button>
            </div>
            <div className="text-white text-center text-sm mt-3">
              {setupErrorMessage}
            </div>
          </div>
        ) : slide === 2 ? (
          <div>
            <p className="text-left text-lg text-white">
              You are officially set up to receive daily portfolio updates.
              Check your phone for a call from Deborah.
            </p>
            <p className="mt-6 text-left text-lg text-white">
              Deborah's phone number is{" "}
              <span className="text-[#F6899F] font-medium">
                +1 415-360-7108
              </span>
              . Make sure to save her in your phone so her call does not go to
              spam.
            </p>
            <p className="mt-6 text-left text-lg text-white">
              Once you've finished your introductory call with Deborah, you can
              continue to your dashboard.
            </p>
            <div className="flex flex-row justify-center items-center">
              <div className="mt-12">
                <button
                  className="mt-4 w-full py-2 px-4 text-black font-medium hover:-translate-y-1 duration-200 ease-in-out bg-[#C2EABD]"
                  onClick={() => navigate("/dashboard")}
                >
                  Go to dashboard
                </button>
                {!welcomeCallSuccess ? (
                  <div>
                    <p className="mt-6 text-white text-center underline">
                      There was an issue calling your phone number. Please
                      update in dashboard to receive your portfolio updates.
                    </p>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default PreferenceBox;
